import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Images } from "../../../themes";
import { useSelector, useDispatch } from "react-redux";
import { saveData } from "../../../utils/api/apiHelper";
import { AddContent } from "../../../utils/api/actionConstants";

import FullScrenLoader from "../../../components/FullScreenLoader";

import UploadService from "./FileUpload";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

const UploadContent = () => {
  const dispatch = useDispatch();
  const folder = useSelector((state) => state.folder);

  const navigate = useNavigate();

  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [progressInfos, setProgressInfos] = useState({ val: [] });
  // const [message, setMessage] = useState([]);
  // const [fileInfos, setFileInfos] = useState([]);
  const progressInfosRef = useRef(null);

  const { state } = useLocation();

  const selectFiles = async (event) => {
    await setSelectedFiles(event.target.files);
    await setProgressInfos({ val: [] });

    setLoading(true);

    setTimeout(() => {
      uploadFiles(event.target.files);
    }, 1000);
  };

  const addContent = (data) => {
    if (data.status && data.status === 1) {
      const url = data.data[0];
      const params = {
        creatorFolderId: state?.id,
        files: [url],
      };
      const payload = {
        action: AddContent,
        data: params,
      };
      dispatch(saveData(payload)).then((r) => {});
    }
  };

  const upload = (idx, file) => {
    let _progressInfos = [...progressInfosRef.current.val];
    return UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round(
        (100 * event.loaded) / event.total
      );
      setProgressInfos({ val: _progressInfos });
    })
      .then((res) => {
        if (res && res.status === 200) {
          addContent(res.data);
        }
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        setProgressInfos({ val: _progressInfos });
      });
  };

  const uploadFiles = (files1) => {
    const files = Array.from(files1);

    let _progressInfos = files.map((file) => ({
      percentage: 0,
      size:
        file.size / 1000000 > 0
          ? `${(file.size / 1000000).toFixed(2)} MB`
          : `${(file.size / 1000).toFixed(2)} KB`,
      fileName: file.name,
      file: file,
    }));

    progressInfosRef.current = {
      val: _progressInfos,
    };

    const uploadPromises = files.map((file, i) => upload(i, file));

    Promise.all(uploadPromises).then((files) => {
      // setFileInfos(files.data);

      setLoading(false);
    });

    // setMessage([]);
  };

  const ShowUploadProgress = () => {
    return progressInfos.val.map((progressInfo, index) => (
      <div className="upload-process" key={index}>
        <figure>
          <img src={URL.createObjectURL(progressInfo.file)} alt="" />
        </figure>
        <div className="upoad-detail">
          <div className="upload-detail-left">
            <h5>{progressInfo.fileName}</h5>
            <p>{progressInfo.size}</p>
          </div>
          <div className="delete-icon">
            <span href="#">
              <img src={Images.deleteIcon} alt="" />
            </span>
          </div>
          <div className="progress">
            <div
              className="bar"
              style={{ width: progressInfo.percentage + "%" }}
            >
              <p className="percent">{progressInfo.percentage}</p>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="wrapper">
      <Header />
      <div className="container">
        <div className="chat-box-wrap">
          <div className="heading-block">
            <span onClick={() => navigate(-1)} className="back-btn">
              <img src={Images.backImg} alt="back-btn" />
            </span>
            <h1>Upload Files to {state.name}</h1>
          </div>
          <div className="upload-wrap">
            <div className="upload-file-tab">
              <input
                type="file"
                multiple
                onDrop={(e) => e.preventDefault()}
                onChange={selectFiles}
                accept=".jpg,.jpeg,.png,.mp4"
              />
              <div className="upload-content">
                <figure>
                  <img src={Images.browseImg} alt="" />
                </figure>
                <p>Upload your photos & video</p>
                <span>Browse</span>
              </div>
            </div>

            {progressInfos &&
              progressInfos.val.length > 0 &&
              ShowUploadProgress()}
          </div>
          <div>
            <span>
          
            </span>
          </div>
          <div className="uploaded-btn-block">
            <span onClick={() => navigate(-1)} className="submit">
              Back to Folder
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UploadContent;
