import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import backBtn from '../../../assets/images/back-btn.png';
import { useRef, useState, useEffect } from "react";
import { UploadFile, UpdateUserpics, UpdateCreator } from "../../../utils/api/actionConstants";
import { useSelector, useDispatch } from "react-redux";
import { Images } from "../../../themes";
import { saveData, saveFormData } from "../../../utils/api/apiHelper";
import { UpdateIsBlur } from "../../../utils/api/actionConstants";
import * as CommonFunc from "../../../utils/Common";
import { updateUserData } from "../../../redux/userReducer";
import FullScreenLoader from "../../../components/FullScreenLoader";
import { AppRoute } from "../../../router/routerConstants";

/*const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB max file size
const ALLOWED_FILE_TYPES = ["image/jpg", "image/jpeg", "image/png"];*/

const AutomatedMessage = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [NavigateTo] = useCustomNavigate();



  const [loading, setLoading] = useState(false);
  const MAX_FILE_SIZE_MB = 1 * 1024 * 1024; // 2MB in bytes
  const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

  const userData = useSelector((state) => state.user.userData);
  const [imgUrl, setImgUrl] = useState("");
  const [message, setMessage] = useState("");
  useEffect(() => {
    setImgUrl(userData.teaserImage);
    setMessage(userData.teaserMessage);
  },[]);
  const handleContinue = (e) => {
    e.preventDefault();

    if (!imgUrl || imgUrl == "") {
      CommonFunc.showError("Please select a teaser Image");
      return;
    }
    if (!message || message == "") {
      CommonFunc.showError("Please enter a message");
      return;
    }

    const params1 = {
      teaserImage: imgUrl,
      teaserMessage: message
    };
    dispatch(updateUserData(params1));
    const payload1 = {
      action: UpdateCreator,
      data: params1,
    };
    dispatch(saveData(payload1)).then((r) => {
      if (r === 1) {
         CommonFunc.showSuccess("Data saved successfully");
         NavigateTo(AppRoute.Profile);
      }
    });
  };

  const onImgClick = (event) => {
    ref.current.click();
  };
  const onImageChange = (e) => {
    setLoading(true);
    const [file] = e.target.files;
    if (file) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        CommonFunc.showErrorFileType("Invalid file type. Please upload a JPEG or PNG image.");
        setLoading(false);
        return;
      }
      if (file.size > MAX_FILE_SIZE_MB) {
        CommonFunc.showErrorFileSize("File is too large. Please upload an image under 1MB.");
        setLoading(false);
        return;
      }
    }
    const fd = new FormData();
    fd.append("file", file);
    setImgUrl("");
    dispatch(saveFormData({ action: UploadFile, data: fd })).then((r) => {
      setLoading(false);
      if (r.length > 0) {
        setImgUrl(r[0]);
      }
    });
  };



  return (
    <>
      <div className="wrapper automate-message">
        <Header showLogout={true} />
        <FullScreenLoader loading={loading} />
        <div className="container">
          <div className="request-purchased-wrap">
            <div className="heading-block">
              <span className="back-btn" onClick={() => NavigateTo(-1)}><img src={backBtn} alt="back-btn" /></span>
            </div>
            <div className="page-heading-block text-center">
              <h2>Automated Message</h2>
            </div>
            <div className="profile-img-seprte">
              <div className="form-inner-holder">
                <div className="mb-2">
                  <div className="main-profile-content">Teaser Image</div>
                  <div className="upload-img">
                    <figure>
                      <img
                        src={
                          imgUrl != "" ? imgUrl :  Images.uploadImgIcon
                        }
                        alt="profile"
                      />
                    </figure>
                    <span className="upload-icon" onClick={onImgClick}>
                      <img src={Images.cameraIcon} alt="" />
                      <input
                        ref={ref}
                        type="file"
                        id="input_file"
                        accept=".jpg,.jpeg,.png"
                        onChange={onImageChange}
                        style={{ display: "none" }}
                      />
                    </span>
                  </div>
                  
                </div>
                <div className="form-group upe">
                      <textarea
                        placeholder="Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => setMessage(event.target.value)}
                      />
                </div>
                <input
                                className="submit"
                                type="button"
                                defaultValue="Save"
                                onClick={handleContinue}
                            />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default AutomatedMessage;