import http from "axios";

import { Baseurl } from "../../../utils/api/Api";

const upload = (file, onUploadProgress) => {
  let formData = new FormData();

  formData.append("file", file);

  return http.post(Baseurl + "upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
};

// const getFiles = () => {
//   return http.get("/files");
// };

const FileUploadService = {
  upload,
  //   getFiles,
};

export default FileUploadService;
