import backBtn from "../../../assets/images/back-btn.png";
import FolderIc from "../../../assets/images/folder-icon.png";
import { Link } from "react-router-dom";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import Header from "../../../components/Header";
import { AppRoute } from "../../../router/routerConstants";
import Footer from "../../../components/Footer";
import CreateFolderModalCreator from "../../../components/CreateFolderModalCreator";
import { getData } from "../../../utils/api/apiHelper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Get_Cons_CreateFolder_Creator } from "../../../utils/api/actionConstants";
import { useSelector } from "react-redux";
import DeleteModal from "../../../components/DeleteModal";
import { useLocation } from "react-router-dom";
import Accordion from "../../../components/Accordion";
const PicandVideos = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [listFolders, setListFolders] = useState([]);
  const [plistFolders, setpListFolders] = useState([]);
  const [deleteFolder, setDeleteFolder] = useState("");
  const folder = useSelector((state) => state.folder?.folders);
  const pifolder = useSelector((state) => state.folder?.pifolder);
  const { state } = useLocation();

   const benefitData = [
      {
        title: 'Notice: Video Upload Issues on Certain Mobile Devices',
        content: `We're currently experiencing video upload issues affecting some cell phone devices. Our team is actively working on the problem, and we expect to have it resolved within two weeks. In the meantime, if you encounter any issues uploading via your cell phone, please try uploading from a computer instead.</br></br>
Thank you for your patience and understanding as we work to improve your experience on Arouzed.com!`
      },
    ];
  const setModalVisibility = () => {
    setShowModal(!showModal);
  };
  const setDeleteVisibility = () => {
    setDeleteModal(!deleteModal);
  };
  const [NavigateTo] = useCustomNavigate();

  useEffect(() => {
    dispatch(
      getData({
        action: Get_Cons_CreateFolder_Creator,
      })
    );
  }, []);

  useEffect(() => {
    if (folder.length !== 0) {
      setListFolders(folder);
    }
    if (pifolder.length !== 0) {
      setpListFolders(pifolder);
    }
  }, [folder, pifolder]);

  const handleDelete = (id) => {
    setDeleteVisibility();
    setDeleteFolder(id);
  };

  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="container">
        <div className="request-purchased-wrap">
          <div className="heading-block">
            <span className="back-btn" onClick={() => NavigateTo(-1)}>
              <img src={backBtn} alt="back-btn" />
            </span>
          </div>
          <div className="page-heading-block text-center">
            <h2>My Pictures and Videos</h2>
          </div>
          <div className="creator-benefits new-data">
            <Accordion data={benefitData} />
          </div>
          <div className="folder-block text-center">
            <div className="form-inner-holder">
              <div className="form-inner-holder-info">
                <p>
                  Your profile can not go live until the Profile Image are completed. Please
                  complete them at your earliest convenience so your profile can
                  be reviewed and, upon approval, go live to members on the
                  platform.
                </p>
              </div>
              <div className="row">
                <div className="col-4">
                  <div
                    className="folder-box"
                    onClick={() => NavigateTo(AppRoute.ProfileImages)}
                  >
                    <div className="folder-image">
                      <img src={FolderIc} alt="" />
                    </div>
                    <h4>Profile Images</h4>
                  </div>
                </div>
                {/*<div className="col-4">
                                <div className="folder-box private-box" onClick={() => NavigateTo(AppRoute.PrivateIntroductoryFolder)}>
                                    <div className="folder-image">
                                        <img src={FolderIc} alt="" />
                                    </div>
                                    <h4>Introductory Private Folder</h4>
                                </div>
                            </div> */}
                {/*plistFolders?.map((item, index) => {
                  return (
                    <div className="col-4" key={index}>
                      <div className="folder-box private-box" NavigateTo>
                        <Link
                          to={`/creator/privateintroductoryfolder`}
                          state={{
                            id: item.id,
                            name: item.name,
                            from: state && state.from,
                          }}
                        >
                          <div className="folder-image">
                            <img src={FolderIc} alt="" />
                          </div>
                          <h4>{item.name}</h4>
                        </Link>
                      </div>
                    </div>
                  );
                })*/}
              </div>
            </div>
          </div>

          <div className="folder-block text-center for-vid">
            <div className="form-inner-holder">
              <div className="form-inner-holder-info for-add-more">
                <p>
                  These folders provide a secure and organized method for
                  content storage and are totally optional.
                </p>
                <div className="floating-btn" onClick={setModalVisibility}>
                  <Link title="Create New Folder">
                    <img src={FolderIc} alt="" />
                  </Link>
                </div>
              </div>
              <div className="row">
                {listFolders?.map((item, index) => {
                  return (
                    <div className="col-4" key={index}>
                      <div className="folder-box" NavigateTo>
                        <button
                          className="delete-folder"
                          onClick={() => handleDelete(item.id)}
                        >
                          x
                        </button>
                        <Link
                          to={`/creator/${item?.name.replace(
                            / /g,
                            "-"
                          )}/folder-files`}
                          state={{
                            id: item.id,
                            name: item.name,
                            from: state && state.from,
                          }}
                        >
                          <div className="folder-image">
                            <img src={FolderIc} alt="" />
                          </div>
                          <h4>{item.name}</h4>
                        </Link>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="col-4">
                                <div className="folder-box">
                                    <Link>
                                        <div className="folder-image">
                                            <img src={FolderIc} alt="" />
                                        </div>
                                        <h4>Adult Photos</h4>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="folder-box">
                                    <Link>
                                        <div className="folder-image">
                                            <img src={FolderIc} alt="" />
                                        </div>
                                        <h4>Adult Videos</h4>
                                    </Link>
                                </div>
                            </div> */}
              </div>
            </div>
          </div>

          

        </div>
      </div>
      <Footer />
      {showModal && (
        <CreateFolderModalCreator modalVisibility={setModalVisibility} />
      )}
      {deleteModal && (
        <DeleteModal
          modalVisibility={setDeleteVisibility}
          deleteFolder={deleteFolder}
        />
      )}
    </div>
  );
};

export default PicandVideos;
